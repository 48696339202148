<template>
  <!-- <div class="calender-section">
    <div class="item-box">
      <div class="title mb-2">
        Mediusware Ltd. Job Guidelines
      </div>
      <div class="video-section overflow-hidden shadow">
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/R9myxRtFZvo?controls=1&autoplay=1&mute=1&rel=0&showinfo=0&loop=1&playlist=R9myxRtFZvo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div> -->

<!--  <div class="calender-section">
    <div class="item-box float-md-end float-sm-end">
      <div class="title">
        Calendar
      </div>
      <div class="calender-section">
        <v-calendar :attributes="attrs" class="max-w-full" is-expanded></v-calendar>
      </div>
    </div>
    <div class="item-box float-md-start float-sm-start">
      <div class="title">
        Upcoming Events
      </div>
      <div class="upcoming-events-section">
        <div class="d-flex" v-for="upcomingEvent in upcomingEvents">
          <div class="upcoming-date">10</div>
          <div class="events-text">
            <h4>{{ upcomingEvent.examName }} Exam for “{{ upcomingEvent.jobName }}”</h4>
            <p>at {{ upcomingEvent.date }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>-->
</template>

<script>
export default {
  name: "Calender",
  data: () => ({
    attrs: [
      {
        key: 'today',
        highlight: 'red',
        dates: new Date(),
      }, {
        key: 'old_day',
        highlight: 'green',
        dates: new Date(2021, 6, 1),
      }, {
        key: 'next_day',
        highlight: 'blue',
        dates: new Date(2021, 6, 20),
      },
    ],
    upcomingEvents: []
  }),
}
</script>

<style scoped>
.title{
  font-size: 24px;
  color:#0060AF
}
.video-section{
  height : 236px;
  border-radius: 10px;
}
@media only screen and (max-width: 575px) {
  .title{
    font-size: 18px;
    color:#0060AF
  }
}
</style>