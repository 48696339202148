<template>
  <div class="dashboard-page">
    <div class="applied-job-section">
      <Calender class="h-736"/>

      <h2>Applied job</h2>
      <div v-for="application in applications"
           class="applied-job-box d-flex justify-content-between align-items-center">
        <div class="job-name">
          <p>You have applied for the job,</p>
          <h4>
            <router-link :to="{ name : 'jobProgress', params : {uuid: application.unique_id}}">
              {{ application.job.title }}
            </router-link>
          </h4>
          <p class="mb-0">at {{ application.created_at }}</p>
        </div>
        <div class="job-status">
          <p><span title="Expected">Exp.</span> Salary</p>
          <h5 class="text-capitalize" :class="checkStatus(application.status)">{{ application.expected_salary }}</h5>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="dashboard-page d-flex">
    <div class="left-content">
      <div class="welcome-section">
        <h1>Hello <b>{{ $store.state.activeUser.full_name }},</b></h1>
        <h1>
          <span> Welcome to Candidate </span><b>Dashboard!</b>
        </h1>
        <p>Check out our latest events and offers and keep learning everyday!</p>

      </div>
      <div class="applied-job-section">
        <h2>Applied job</h2>
        <div v-for="application in applications"
             class="applied-job-box d-flex justify-content-between align-items-center">
          <div class="job-name">
            <p>You have applied for the job,</p>
            <h4>
              <router-link :to="{ name : 'jobProgress', params : {uuid: application.unique_id}}">
                {{ application.job.title }}
              </router-link>
            </h4>
            <p class="mb-0">at {{ application.created_at }}</p>
          </div>
          <div class="job-status">
            <p><span title="Expected">Exp.</span> Salary</p>
            <h5 class="text-capitalize" :class="checkStatus(application.status)">{{ application.expected_salary }}</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="right-content">
      <Calender/>
    </div>
  </div> -->
</template>

<script>
import Calender from "../../components/Calender";
import axios from "@/axios";

export default {
  name      : "Dashboard",
  components: {Calender},
  data      : () => ({
    applications: [],
  }),
  methods   : {
    checkStatus(status) {
      let data = "";
      switch (status) {
        case "pending":
          data = "status-pending";
          break;
        case "reviewed":
          data = "status-reviewed";
          break;
        case "rejected":
          data = "status-rejected";
          break;
      }
      return data;
    }
  },
  mounted() {
    axios.get('/apply/').then(response => {
      this.applications = response.data
    }).catch(error => {
      console.error(error.response)
    })
  }
}
</script>

<style lang="scss">

.h-736 .video-section {
  height        : 736px;
  margin-bottom : 40px;
  @media (max-width : 991px) {
    height : 300px;
  }
  @media (max-width : 575px) {
    height : 188px;
    margin-bottom  : 24px;
  }
}
</style>